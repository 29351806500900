@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');
body {
    font-family: "Ubuntu";
    font-size: 0.9rem;
    font-weight: lighter;
    overflow: hidden;
}

.bg-grid {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='rgb(51 65 85 / 0.25)'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
    mask-image: linear-gradient(0deg, rgba(255,255,255,.1), rgba(255,255,255,.4));
}

.btn {
    height: 2.5rem;
    min-height: 2rem;
}

/*.dropdown .menu a:active {*/
/*    background-color: transparent;*/
/*    color: inherit;*/
/*}*/