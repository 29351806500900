.btn-on-off {
    @apply
        flex
        z-10
        w-64
        h-16
        m-auto
        rounded-lg

        border
        border-gray-700
        
        text-gray-500
        bg-gradient-to-b
        from-slate-800
        to-slate-900

        hover:from-slate-700
        hover:to-slate-900
        hover:text-primary
        cursor-pointer
    ;
}

.btn-loading {
    @apply
        flex
        z-10
        w-64
        h-16
        m-auto
        rounded-lg

        border
        border-gray-700
        
        bg-gradient-to-b

        from-slate-700
        to-slate-900
        text-gray-400
        cursor-pointer
    ;
}

.p-calendar .p-button {
    @apply
        w-8
        h-8
        px-2
        py-1
        rounded-md
        border
        border-gray-700
        hover:border-gray-700
        text-gray-400
        hover:text-gray-400
        text-center
        md:hover:bg-gray-700
        md:cursor-pointer
        focus:ring-0
}

.p-calendar.active .p-button {
    @apply
        bg-black
        text-white
        border-primary
        border-2
}

.p-calendar .p-inputtext {
    @apply
        absolute
        w-0
        h-0
        invisible
        top-[10px]
}

.p-button[aria-label="Today"] {
    display: none;
}

/* Disable month/year navigator */
.p-datepicker-month, .p-datepicker-year  {
    opacity: 0.4;
    cursor: default !important;
    pointer-events: none;
}
